import { useMemo, useState } from "react";
import { NetworkStatus, useQuery } from "@apollo/client";
import { fetchPostQuery, fetchPostsPreviewsQuery } from "../queries/Post";
import {
  FetchPostResponse,
  FetchPostsQueryResponse,
  FetchPostsQueryVariables,
} from "../types/PostTypes";

export const useFetchPosts = ({
  initialPageSize = 10,
}: {
  initialPageSize?: number;
}) => {
  const currentDate = useMemo(() => new Date(), []);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const { data, loading, error, fetchMore, networkStatus } = useQuery<
    FetchPostsQueryResponse,
    FetchPostsQueryVariables
  >(fetchPostsPreviewsQuery, {
    variables: { currentDate, first: pageSize },
    notifyOnNetworkStatusChange: true,
  });

  const posts = data?.postsConnection.edges || [];

  const loadMore = () => {
    fetchMore({
      variables: {
        first: (posts.length ?? 0) + initialPageSize,
        currentDate,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        return fetchMoreResult;
      },
    });
  };

  const isFetching = networkStatus === NetworkStatus.fetchMore;

  return {
    posts,
    loading,
    isFetching,
    error,
    loadMore,
    hasMore: data?.postsConnection.pageInfo.hasNextPage,
    totalElements: data?.postsConnection.aggregate.count,
  };
};
export const useFetchPost = (slug: string) => {
  return useQuery<FetchPostResponse>(fetchPostQuery, {
    variables: {
      slug,
    },
  });
};
