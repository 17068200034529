import {
  RichText as BaseRichText,
  RichTextProps as BaseRichTextProps,
} from "@graphcms/rich-text-react-renderer";
import { NodeRendererType } from "@graphcms/rich-text-react-renderer/dist/types";
import { ElementNode } from "@graphcms/rich-text-types";

import {
  Box,
  Heading,
  Image,
  Link,
  OrderedList,
  ResponsiveValue,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import YouTubePlayer from "../YouTubePlayer/YouTubePlayer";

type RichTextProps = BaseRichTextProps;

type ElementProps = {
  children: {
    props: {
      content: ElementNode[];
    };
  };
};
export const wrapWithContentBox = (
  component: JSX.Element,
  params?: {
    width?: ResponsiveValue<string | number>;
    margin?: ResponsiveValue<string | number>;
  }
) => {
  const width = params?.width || 808;
  const margin = params?.margin ?? {
    base: "0.75rem",
    sm: "1rem",
    xl: "1.5rem",
  };
  return (
    <Box minWidth="fit-content" mb={margin} maxW={width} mx="auto">
      {component}
    </Box>
  );
};

const BASE_RENDERERS: NodeRendererType = {
  a: ({ href, ...props }) => {
    return href?.includes("youtube.com") ? (
      <YouTubePlayer videoUrl={href} />
    ) : (
      <Link
        href={href as string}
        color="orange"
        fontWeight={600}
        textDecor="underline"
        fontSize={{ base: "0.875rem", sm: "1rem" }}
        {...props}
      />
    );
  },

  h1: (props) =>
    wrapWithContentBox(
      <Heading
        as="h2"
        {...props}
        fontSize={{
          base: "1rem",
          md: "1.25rem",
          xl: "1.5rem",
        }}
        lineHeight="normal"
      />,
      {
        width: "100%",
        margin: { base: "0.5rem", sm: "0.75rem" },
      }
    ),
  h2: (props) =>
    wrapWithContentBox(
      <Heading
        as="h2"
        textAlign="center"
        fontFamily="GaretHeavy"
        {...props}
        fontSize={{
          base: "1.25rem",
          md: "1.5rem",
          xl: "1.75rem",
        }}
        lineHeight={{ base: "1.5rem", sm: "2rem" }}
      />,
      {
        width: "100%",
        margin: { base: "0.5rem", sm: "0.75rem" },
      }
    ),
  h3: (props) =>
    wrapWithContentBox(
      <Heading
        as="h3"
        textAlign="center"
        fontFamily="GaretHeavy"
        {...props}
        lineHeight={{ base: "1.5rem", sm: "2rem" }}
        fontSize={{
          base: "1.25rem",
          md: "1.5rem",
          xl: "1.75rem",
        }}
      />,
      {
        width: "100%",
        margin: { base: "0.5rem", sm: "0.75rem" },
      }
    ),
  h4: (props) =>
    wrapWithContentBox(
      <Text
        as="h4"
        fontWeight="500"
        mb={{ base: "-0.5rem", sm: "-1rem" }}
        {...props}
        lineHeight={{ base: "1.5rem", sm: "2rem" }}
        fontSize={{
          base: "1.25rem",
          md: "1.5rem",
          xl: "1.75rem",
        }}
      />,
      {
        width: "100%",
        margin: { base: "0.5rem", sm: "0.75rem" },
      }
    ),
  h5: (props) =>
    wrapWithContentBox(
      <Text
        as="h5"
        mb={{ base: "-0.5rem", sm: "-1rem" }}
        fontWeight="500"
        {...props}
        lineHeight={{ base: "1.5rem", sm: "2rem" }}
        fontSize={{
          base: "1.25rem",
          md: "1.5rem",
          xl: "1.75rem",
        }}
      />,
      {
        width: "100%",
        margin: { base: "0.5rem", sm: "0.75rem" },
      }
    ),
  h6: (props) =>
    wrapWithContentBox(
      <Text
        as="h6"
        fontWeight="500"
        mb={{ base: "-0.5rem", sm: "-1rem" }}
        {...props}
        lineHeight={{ base: "1.5rem", sm: "2rem" }}
        fontSize={{
          base: "1.25rem",
          md: "1.5rem",
          xl: "1.75rem",
        }}
      />,
      {
        width: "100%",
        margin: { base: "0.5rem", sm: "0.75rem" },
      }
    ),
  p: (props) => {
    const content = (props as ElementProps).children.props.content;
    const isVideo =
      content.filter((el) => el?.text !== "").length == 1 &&
      content.at(1)?.href.includes("youtube.com");

    return wrapWithContentBox(
      <Text
        lineHeight={{ base: "1rem", sm: "1.5rem" }}
        size={{ base: "sm", sm: "md" }}
        maxWidth={isVideo ? "100%" : 808}
        {...props}
      />,
      {
        width: isVideo ? "100%" : 808,
        margin: isVideo
          ? { base: "1rem", sm: "1.5rem", xl: "2.5rem" }
          : undefined,
      }
    );
  },
  blockquote: (props) => (
    <Box
      bg="#F0F0F0"
      mx="auto"
      border="0.065rem solid black"
      borderRadius="0.25rem"
      padding={{ base: "0.75rem", sm: "1.5rem" }}
      maxWidth={808}
      mb={{ base: "0.75rem", sm: "1.5rem" }}
    >
      <Text
        fontSize={{ base: "md", sm: "lg" }}
        lineHeight={{ base: "1.5rem", sm: "2rem" }}
        {...props}
      />
    </Box>
  ),

  bold: (props) => (
    <Text
      maxWidth={808}
      as="b"
      lineHeight={{ base: "1rem", sm: "1.5rem" }}
      size={{ base: "sm", sm: "md" }}
      fontWeight={600}
      {...props}
    />
  ),
  italic: (props) => (
    <Text
      maxWidth={808}
      as="em"
      lineHeight={{ base: "1rem", sm: "1.5rem" }}
      size={{ base: "sm", sm: "md" }}
      fontStyle="italic"
      {...props}
    />
  ),

  ul: (props) =>
    wrapWithContentBox(
      <UnorderedList
        fontSize={{ base: "0.875rem", sm: "1rem" }}
        lineHeight={{ base: "1rem", sm: "1.5rem" }}
        spacing={{ base: "0.75rem", sm: "1.25rem" }}
        maxWidth={808}
        {...props}
      />
    ),
  ol: (props) =>
    wrapWithContentBox(
      <OrderedList
        fontSize={{ base: "0.875rem", sm: "1rem" }}
        lineHeight={{ base: "1rem", sm: "1.5rem" }}
        spacing={{ base: "0.75rem", sm: "1.25rem" }}
        maxWidth={808}
        {...props}
      />
    ),
  img: ({ src, altText }) =>
    wrapWithContentBox(
      <Image
        borderRadius="1.25rem"
        src={src}
        alt={altText}
        width="100%"
        maxW="100%"
        objectFit="cover"
        objectPosition="center"
        maxHeight="100vh"
      />,
      {
        width: "100%",
        margin: { base: "1rem", sm: "1.5rem", xl: "2.5rem" },
      }
    ),
};

export const RichTextRenderer = ({
  content,
  references,
  renderers = BASE_RENDERERS,
}: RichTextProps) => (
  <BaseRichText
    content={content}
    references={references}
    renderers={{
      ...BASE_RENDERERS,
      ...renderers,
    }}
  />
);
