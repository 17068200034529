import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
  filter,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";
import { FilterOptionsType } from "../../api/types";
import SearchBar from "../SearchBar/SearchBar";
import FilterItem from "../FilterItem/FilterItem";
import { Book } from "../../../HomePage/components/Book/Book";
import { Paginate } from "../../../../ui/components/Pagination/Pagination";
import Loader from "../../../../ui/components/Loader/Loader";
import useFilteredBook from "../../api/client";
import notFoundImage from "../../../../assets/Vovchok.png";
import { useSearchParams } from "react-router-dom";
interface BooksCatalogueProps {
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptionsType>>;
  filterOptions: FilterOptionsType;
}

const BooksCatalogue: FunctionComponent<BooksCatalogueProps> = ({
  setFilterOptions,
  filterOptions,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get('page')??'1'));
  useEffect(() => {
    setCurrentPage(parseInt(searchParams.get('page')??'1'));
  }, [searchParams.get('page')])
  const setSearch = (text: string) => {
      setFilterOptions((prevFilterOption) => {
      return (
        prevFilterOption && {
          ...prevFilterOption,
          searchParameter: text,
        }
      );
    });
  }

  const {
    data: bookList,
    isLoading,
    error,
  } = useFilteredBook(filterOptions, currentPage, 12);


  const pageCount = Math.ceil(
    (bookList?.paging?.totalElements ?? 1) /
      parseInt(bookList?.paging?.limit ?? "1")
  );

  const handleChange = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString()})
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
      setSearchParams({page: (currentPage-1).toString()})
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
      setSearchParams({page: (currentPage+1).toString()})
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const clearFilters = () => {
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          bestsellers: false,
          newcomings: false,
          genres: [],
          authors: [],
          publishers: [],
          searchParameter: prevFilterOptions.searchParameter,
        }
      );
    });
    setSearchParams({ page: '1'})
  };

  const optionsToStringFilters = () => {
    const filterEntities = Object.entries(filterOptions)
      .filter((filter) => typeof filter[1] !== "string")
      .reduce((filterList: Array<any>, filter) => {
        typeof filter[1] === "object"
          ? filter[1].forEach((element) => {
              filterList.push({
                name: element,
                key: filter[0],
              });
            })
          : filter[1] &&
            filterList.push({
              key: filter[0],
              name: filter[0] === "bestsellers" ? "Бестселери" : "Новинки",
            });
        return filterList;
      }, []);
    return filterEntities;
  };

  const removeFilter = (key: string, name: string) => {
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,

          [key]:
            typeof (prevFilterOptions as { [key: string]: any })[key] ===
            "object"
              ? (prevFilterOptions as { [key: string]: any })[key] &&
                (prevFilterOptions as { [key: string]: any })[key].filter(
                  (filter: string) => filter !== name
                )
              : false,
        }
      );
    });
    setSearchParams({ page: '1'})
  };

  const isNonEmpty = (arr: any[]) => arr.length > 0;

  const isAnyFilterSelected =
    isNonEmpty(filterOptions.genres) ||
    isNonEmpty(filterOptions.authors) ||
    isNonEmpty(filterOptions.publishers) ||
    filterOptions.bestsellers ||
    filterOptions.newcomings;

  return (
    <Box
      transition="all 1s"
      paddingTop={{
        base: "1rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
      flexGrow={1}
    >
      <Breadcrumb
        breadcrumbItems={[
          { path: "/", title: "Головна" },
          { title: "Каталог" },
        ]}
      />
      <Box
        marginTop={{
          base: "1.25rem",
          sm: "0.5rem",
          md: "1rem",
          xl: "1.5rem",
        }}
        marginBottom={{
          base: "1.75rem",
          sm: "1.5rem",
          md: "2rem",
          xl: "2.625rem",
        }}
      >
        <Flex
          position="relative"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={{ base: "0.5rem", md: "1rem", xl: "1.5rem" }}
        >
          <Text
            fontWeight={600}
            as="h1"
            fontSize={{
              base: "1.75rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
            variant="primary"
          >
            Каталог
          </Text>
          <SearchBar
            initialValue={filterOptions.searchParameter}
            color="#F0F0F0"
            placeholder="Пошук книг"
            setState={setSearch}
          />
        </Flex>
        {((filterOptions?.searchParameter !== "" && filterOptions.searchParameter)|| isAnyFilterSelected) && ( 
          <Flex gap="1rem" alignItems="center" justifyContent="space-between">
            <Stack gap={{ base: "0.75rem", lg: "1.25rem" }}>
              {(bookList?.books.length??0) > 0 && filterOptions.searchParameter && filterOptions.searchParameter !== "" && (
                <Text
                  fontSize={{
                    base: "1rem",
                    sm: "0.75rem",
                    md: "1rem",
                    xl: "1.25rem",
                  }}
                >
                  <b>{`“${filterOptions.searchParameter}”`}</b>
                </Text>
              )}
              {isAnyFilterSelected && (
                <Flex
                  alignItems="center"
                  gap="1rem"
                  flexWrap="wrap"
                  flexGrow={1}
                >
                  {optionsToStringFilters()
                    .flat()
                    .map((filter: any) => {
                      return (
                        <FilterItem
                          removeFilter={removeFilter}
                          filter={filter}
                        />
                      );
                    })}
                  <Button
                    onClick={clearFilters}
                    _hover={{ opacity: 0.8 }}
                    _active={{}}
                    backgroundColor="orange"
                    border="0.0625rem solid #FF5500"
                    borderRadius="0.5rem"
                    px={{ base: "0.5rem", lg: "1rem" }}
                    py={{ base: "0.3rem", lg: "0.625rem" }}
                  >
                    <Text
                      size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                      color="white"
                    >
                      Очистити
                    </Text>
                  </Button>
                </Flex>
              )}
            </Stack>
            {bookList && (bookList?.books.length??0) > 0 && filterOptions.searchParameter && (filterOptions.searchParameter !== "" || isAnyFilterSelected) && (
              <Text
                whiteSpace="nowrap"
                fontWeight={400}
                variant="secondary"
                size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
              >
                {bookList?.paging.totalElements}{" "}
                {bookList?.paging.totalElements === 1
                  ? "результат"
                  : bookList?.paging.totalElements > 0 &&
                    bookList?.paging.totalElements < 5
                  ? "результати"
                  : "результатів"}
              </Text>
            )}
          </Flex>
        )}
      </Box>
      <Box
        borderRadius={{ base: "1rem", md: "1.5rem", xl: "2rem" }}
        width="100%"
        transition="all 0.3s"
        marginBottom={{
          base: "2rem",
          sm: "1.5rem",
          md: "3rem",
          "2xl": "6.25rem",
        }}
      >
        {isLoading ? (
          <Loader width="100%" />
        ) : bookList?.books.length !== 0 ? (
          <SimpleGrid
            columns={{ base: 2, sm: 3, lg: 4 }}
            spacing={{ base: "1rem", md: "1.25rem", lg: "1.5rem", xl: "2rem" }}
            rowGap={{
              base: "2rem",
              md: "2.5rem",
              lg: "3.15rem",
              xl: "4.25rem",
            }}
          >
            {bookList?.books.map((book) => {
              return <Book key={book.id} {...book} />;
            })}
          </SimpleGrid>
        ) : (
          <Stack
            textAlign="center"
            fontSize={{
              base: "1rem",
              sm: "0.75rem",
              md: "1rem",
              xl: "1.25rem",
            }}
            alignItems="center"
          >
            <Image
              marginBottom={{ base: "1rem", xl: "2rem" }}
              width={{ base: "12rem", xl: "15.25rem" }}
              src={notFoundImage}
            />
            <Text fontSize="inherit" textAlign="inherit">
              За вашим запитом {filterOptions.searchParameter && <b>{`“${filterOptions.searchParameter}”`}</b>} нічого не
              знайдено.
            </Text>
            <Text textAlign="inherit" fontSize="inherit">
              Спробуйте ще.
            </Text>
          </Stack>
        )}
      </Box>
      <Paginate
        pageCount={pageCount ?? 1}
        previousPage={previousPage}
        nextPage={nextPage}
        currentPage={currentPage}
        onChange={handleChange}
      />
    
    </Box>
  );
};

export default BooksCatalogue;
