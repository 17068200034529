import {
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import phoneImage from "../../assets/Phone.png";
import appleStoreBadge from "../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googlePlayBadge from "../../assets/google-play-badge.png";

interface ClassificationsPageProps {}

const ClassificationsPage: FunctionComponent<ClassificationsPageProps> = () => {
  return (
    <Flex
      gap={{ base: "2.5rem", "2xl": "5rem" }}
      alignItems="center"
      justifyContent="center"
      position="relative"
      flexDirection={{ base:'column', md:'row'}}
    >
      <Image
        alt="Phone image"
        src={phoneImage}
        width={{ base: "46.75%", sm: "30%", md: "22%", "2xl": "17%" }}
        height='auto'
      />
      <Stack
        maxW="fit-content"
        gap={{ base: "1.5rem", xl: "3rem" }}
        alignItems="center"
      >
        <Text
          fontWeight={300}
          fontSize={{
            base: "1rem",
            sm: "1.5rem",
            md: "1,75rem",
            xl: "2.25rem",
          }}
          maxWidth={{ base: "80%", sm: 720, md: 960 }}
          textAlign="center"
          fontFamily="Garet"
          as="h2"
        >
          Встановлюй мобільний додаток Читанки та досліджуй наші унікальні
          підбірки книжок.
        </Text>
        <Flex
          mt="0 !important"
          flexDirection={{ base: "column", sm: "row" }}
          alignItems='center'
          minWidth="fit-content"
          width={{ base: "100%", sm: "auto" }}
          gap={{ base: "0.75rem", md: "1.25rem" }}
          zIndex={3}
        >
          <Link
            maxWidth={{ base: "55%", md: "51.25%" }}
            isExternal
            href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
          >
            <Image
              width={{ base: "%", sm: "auto" }}
              height={{
                base: "auto",
                sm: "3rem",
                xl: "4rem",
              }}
              src={googlePlayBadge}
              alt="Google badge"
            />
          </Link>
          <Link
            margin="0 !important"
            width={{ base: "55%", md: "46.25%" }}
            isExternal
            href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
          >
            <Image
              flexShrink={1}
              width={{ base: "100%", sm: "auto" }}
              height={{
                base: "auto",
                sm: "3rem",
                xl: "4rem",
              }}
              src={appleStoreBadge}
              alt="App store badge"
            />
          </Link>
        </Flex>
      </Stack>
    </Flex>
  );
};

export default ClassificationsPage;
