import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";
import { FilterOptionsType } from "../../api/types";
import SearchBar from "../SearchBar/SearchBar";
import { Book } from "../../../HomePage/components/Book/Book";
import { Paginate } from "../../../../ui/components/Pagination/Pagination";
import Loader from "../../../../ui/components/Loader/Loader";
import useFilteredBook from "../../api/client";
import notFoundImage from "../../../../assets/Vovchok.png";
import FilterIcon from "../../../../ui/icons/FilterIcon";
import { useSearchParams } from "react-router-dom";

interface BooksCatalogueProps {
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptionsType>>;
  filterOptions: FilterOptionsType;
  setShowFilterPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileBooksCatalogue: FunctionComponent<BooksCatalogueProps> = ({
  setFilterOptions,
  filterOptions,
  setShowFilterPanel,
}) => {

  let [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get('page')??'1'));
  useEffect(() => {
    setCurrentPage(parseInt(searchParams.get('page')??'1'));
  }, [searchParams.get('page')])
  const {
    data: bookList,
    isLoading,
    error,
  } = useFilteredBook(filterOptions, currentPage, 12);

  const pageCount = Math.ceil(
    (bookList?.paging?.totalElements ?? 1) /
      parseInt(bookList?.paging?.limit ?? "1")
  );

  const handleChange = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString()})
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
      setSearchParams({page: (currentPage-1).toString()})
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
      setSearchParams({page: (currentPage+1).toString()})
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const isNonEmpty = (arr: any[]) => arr.length > 0;

  const isAnyFilterSelected =
    isNonEmpty(filterOptions.genres) ||
    isNonEmpty(filterOptions.authors) ||
    isNonEmpty(filterOptions.publishers) ||
    filterOptions.bestsellers ||
    filterOptions.newcomings;

    const setSearch = (text: string) => {
      setFilterOptions((prevFilterOption) => {
      return (
        prevFilterOption && {
          ...prevFilterOption,
          searchParameter: text,
        }
      );
    });
  }
  return (
    <Box transition="all 1s" paddingTop="1.25rem" flexGrow={1}>
      <Breadcrumb
        breadcrumbItems={[
          { path: "/", title: "Головна" },
          { title: "Каталог" },
        ]}
      />
      <Box marginTop="1rem" marginBottom="1rem">
        <Flex
          position="relative"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="1rem"
        >
          <Text fontWeight={600} as="h1" fontSize="1.75rem" variant="primary">
            Каталог
          </Text>
          <Button
            position="relative"
            onClick={() => setShowFilterPanel(true)}
            px="0.75rem"
            py="0.65rem"
            backgroundColor="grey"
            borderRadius="0.5rem"
          >
            <FilterIcon width="1rem" />
            <Text as='h2' fontWeight={500} marginLeft="0.5rem" size="md">
              Фільтр
            </Text>
            {isAnyFilterSelected && (
              <Box
                position="absolute"
                backgroundColor="orange"
                borderRadius="50%"
                right="0.375rem"
                top="0.375rem"
                width="0.375rem"
                height="0.375rem"
              ></Box>
            )}
          </Button>
        </Flex>
      </Box>
      <SearchBar
        initialValue={filterOptions.searchParameter}
        color="#F0F0F0"
        placeholder="Пошук книг"
        setState={setSearch}
      />
      {(bookList?.books.length??0) > 0 && filterOptions.searchParameter && filterOptions.searchParameter !== "" && (
        <Text marginTop="1.25rem" marginBottom="1.25rem" size="md">
          <b>{`“${filterOptions.searchParameter}”`}</b>
        </Text>
      )}
      <Box
        borderRadius="1rem"
        width="100%"
        transition="all 0.3s"
        marginBottom="2rem"
        marginTop="2rem"
      >
        {isLoading ? (
          <Loader />
        ) : bookList?.books.length !== 0 ? (
          <SimpleGrid columns={2} spacing="1rem" rowGap="2rem">
            {bookList?.books.map((book) => {
              return <Book key={book.id} {...book} />;
            })}
          </SimpleGrid>
        ) : (
          <Stack textAlign="center" fontSize="1rem" alignItems="center">
            <Image alt='Not found' marginBottom="1rem" width="12rem" src={notFoundImage} />
            <Text fontSize="inherit" textAlign="inherit">
              За вашим запитом {filterOptions.searchParameter && <b>{`“${filterOptions.searchParameter}”`}</b>} нічого не
              знайдено.
            </Text>
            <Text textAlign="inherit" fontSize="inherit">
              Спробуйте ще.
            </Text>
          </Stack>
        )}
      </Box>
      <Paginate
        pageCount={pageCount ?? 1}
        previousPage={previousPage}
        nextPage={nextPage}
        currentPage={currentPage}
        onChange={handleChange}
      />{" "}
    </Box>
  );
};

export default MobileBooksCatalogue;
