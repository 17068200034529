import {
  Box,
  Flex,
  Image,
  Stack,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { PostType } from "../../../../graphcms/types/PostTypes";
import { formatDate } from "../../../../utilities/formatDate";
import { NavLink } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

interface PostPreviewItemProps extends PostType {}

const PostPreviewItem: FunctionComponent<PostPreviewItemProps> = ({
  heroImage,
  publishingDate,
  slug,
  title,
}) => {
  return (
    <Stack
      mt="0 !Important"
      gap={{ base: "0.75rem", md: "1rem", xl: "1.25rem" }}
    >
      {heroImage && (
        <NavLink to={`/posts/${slug}`}>
          <Image
            width="100%"
            objectFit="cover"
            objectPosition="center"
            sx={{ aspectRatio: "1.81" }}
            alt={title}
            borderRadius="1.25rem"
            src={heroImage?.url}
          />
        </NavLink>
      )}
      <Stack
        mt="0!important"
        justifyContent="space-between"
        flexGrow={1}
        gap="0.75rem"
      >
        <NavLink to={`/posts/${slug}`}>
          <Text
            _hover={{
              textDecor: "underline",
            }}
            noOfLines={4}
            textTransform="uppercase"
            fontSize={{
              base: "1.25rem",
              md: "1.5rem",
              xl: "1.75rem",
            }}
            fontWeight={500}
            as="h4"
          >
            {title}
          </Text>
        </NavLink>
        <Text
          lineHeight="1rem"
          mt="0 !important"
          fontSize={{
            base: "0.75rem",
            sm: "0.5rem",
            md: "0.75rem",
            xl: "0.875rem",
          }}
          variant="secondary"
        >
          {formatDate(publishingDate)}
        </Text>
      </Stack>
    </Stack>
  );
};

export default PostPreviewItem;
