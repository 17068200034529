import { useParams } from "react-router-dom";
import { useFetchPost } from "../../graphcms/hooks/Post";
import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react";
import Seo from "../../ui/components/Seo/Seo";
import Loader from "../../ui/components/Loader/Loader";
import { RichTextRenderer } from "../../features/PostsPage/components/RichTextRenderer/RichTextRenderer";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import { formatDate } from "../../utilities/formatDate";

const PostPage = () => {
  const { id } = useParams();
  const { data: postData, loading: isLoading } = useFetchPost(id as string);

  const post = postData?.post;

  return (
    <>
      <Seo
        description={post?.excerpt ?? "Пост"}
        title={post?.title ?? "Пост"}
      />
      {post ? (
        <Stack
          width="100%"
          px={{
            base: "0.5rem",
            md: "1rem",
            xl: "2rem",
            "2xl": "4.75rem",
          }}
          gap={{ base: "1rem", sm: "1.5rem", md: "2rem", xl: "2.5rem" }}
        >
          <Breadcrumb
            breadcrumbItems={[
              { path: "/", title: "Головна" },
              { title: "Блог", path: "/posts" },
              { title: post.title },
            ]}
          />
          <Stack
            gap={{
              base: "2rem",
              sm: "1.75rem",
              xl: "2.5rem",
            }}
            alignSelf="center"
            alignItems="center"
            mt="0 !Important"
            maxWidth={1088}
            width="100%"
          >
            <Stack
              gap={{ base: "0.5rem", md: "0.75rem" }}
              maxW={808}
              width="100%"
            >
              <Text
                size={{
                  base: "lg",
                  md: "heading",
                  xl: "xl",
                }}
                mt="0 !important"
                lineHeight="normal"
                fontWeight="800"
                as="h1"
              >
                {post.title}
              </Text>
              <Text
                mt="0 !Important"
                size={{ base: "md", md: "lg" }}
                opacity="0.5"
                lineHeight={{ base: "1.5rem", md: "2rem" }}
              >
                {post.excerpt}
              </Text>
              <Text
                lineHeight="1rem"
                mt="1.75rem"
                fontSize={{
                  base: "0.75rem",
                  sm: "0.5rem",
                  md: "0.75rem",
                  xl: "0.875rem",
                }}
                variant="secondary"
                alignSelf="flex-start"
              >
                {formatDate(post.publishingDate)}
              </Text>
            </Stack>

            {post.heroImage && (
              <Image
                mt="0 !important"
                width="100%"
                maxHeight="min(100vh, 67.5rem)"
                objectPosition="center"
                objectFit="cover"
                alt={post?.title ?? "Post"}
                src={post?.heroImage?.url}
                borderRadius="0.75rem"
              />
            )}
            <Box
              mb={{
                base: "-0.75rem !important",
                sm: "-1.5rem !important",
              }}
              maxWidth={808}
              width="100%"
              mt="0 !important"
            >
              <RichTextRenderer content={post.content.raw} />
            </Box>
          </Stack>
        </Stack>
      ) : isLoading ? (
        <Loader
          width="100%"
          minHeight="10vh"
          height="100%"
          alignSelf="center"
          justifySelf="center"
          flexGrow={1}
        />
      ) : (
        <Center height={{ base: "50vh" }}>
          <Text
            opacity={0.5}
            size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
            fontWeight={500}
          >
            Не вдалося знайти пост
          </Text>
        </Center>
      )}
    </>
  );
};

export default PostPage;
