import { useQuery } from "react-query";
import api from "../../../services/api/api";
import { GenreResponseType } from "./types";
import { BooksResponseType } from "../../HomePage/api/types";
export const useGenres = (page?: number, limit?: number) => {
  return useQuery(
    ["genres", page, limit],
    () => {
      return api.get<string, GenreResponseType>("/genres" + (typeof page !== 'undefined' && typeof limit !== 'undefined' ? `?page=${page}&limit=${limit}` :""));
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useBooksByGenre = (
  genre: string,
  page: number,
  limit: number,
) => {
  return useQuery(
    [genre, page, limit],
    () => {
      return api.get<string, BooksResponseType>(
        `/booksByGenre?genre=${genre}&page=${page}&limit=${limit}`
      );
    },
    {
      refetchOnWindowFocus: false,
      suspense: false,
      enabled: genre !== "" ,
    }
  );
};

