import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useMemo, useState } from "react";
import { FilterOptionsType } from "../../api/types";
import { IoMdClose } from "react-icons/io";
import { usePublishers } from "../../../HomePage/api/client";
import { useGenres } from "../../../SelectedCategoryPage/api/client";
import SearchBar from "../SearchBar/SearchBar";
import useFilteredBook, { useAuthors } from "../../api/client";
import { useSearchParams } from "react-router-dom";

interface MobileFilterPanelProps {
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptionsType>>;
  filterOptions: FilterOptionsType;
  showFilterPanel: boolean;
  setShowFilterPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileFilterPanel: FunctionComponent<MobileFilterPanelProps> = ({
  setFilterOptions,
  showFilterPanel,
  filterOptions,
  setShowFilterPanel,
}) => {
  const { data: bookList, isLoading: booksLoading } = useFilteredBook(
    filterOptions,
    1,
    12
  );
  let [searchParams, setSearchParams] = useSearchParams();

  const handleBookTypeCheck = (e: any) => {
    const { name, checked } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          [name]: checked,
        }
      );
    });
    setSearchParams({ page: "1" });
  };

  const handleGenreCheck = (e: any) => {
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          genres: prevFilterOptions.genres.includes(name)
            ? prevFilterOptions.genres.filter((genre) => genre !== name)
            : [...(prevFilterOptions?.genres ?? []), name],
        }
      );
    });
    setSearchParams({ page: "1" });
  };

  const handleAuthorCheck = (e: any) => {
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          authors: prevFilterOptions.authors.includes(name)
            ? prevFilterOptions.authors.filter((author) => author !== name)
            : [...(prevFilterOptions?.authors ?? []), name],
        }
      );
    });
    setSearchParams({ page: "1" });
  };

  const handlePublisherCheck = (e: any) => {
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          publishers: prevFilterOptions.publishers.includes(name)
            ? prevFilterOptions.publishers.filter(
                (publisher) => publisher !== name
              )
            : [...(prevFilterOptions?.publishers ?? []), name],
        }
      );
    });
    setSearchParams({ page: "1" });
  };

  const { data: publisherList } = usePublishers(true);
  const { data: genreList } = useGenres();
  const { data: authorsList } = useAuthors();

  const [publisherSearchParameter, setPublisherSearchParameter] =
    useState<string>("");
  const [authorSearchParameter, setAuthorSearchParameter] =
    useState<string>("");

  const filteredPublishers = useMemo(() => {
    return publisherList?.publishingHouses.filter(
      (publisher) =>
        publisher.name
          .toLowerCase()
          .includes(publisherSearchParameter.toLowerCase()) ||
        filterOptions?.publishers.includes(publisher.name)
    );
  }, [publisherSearchParameter, publisherList, filterOptions?.publishers]);

  const filteredAuthors = useMemo(() => {
    return authorsList?.authors.filter(
      (author) =>
        author.name
          .toLowerCase()
          .includes(authorSearchParameter.toLowerCase()) ||
        filterOptions?.authors.includes(author.name)
    );
  }, [authorSearchParameter, authorsList, filterOptions?.authors]);

  const clearFilters = () => {
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          bestsellers: false,
          newcomings: false,
          genres: [],
          authors: [],
          publishers: [],
          searchParameter: prevFilterOptions.searchParameter,
        }
      );
    });
    setSearchParams({ page: "1" });
  };

  const isNonEmpty = (arr: any[]) => arr.length > 0;

  const isAnyFilterSelected =
    isNonEmpty(filterOptions.genres) ||
    isNonEmpty(filterOptions.authors) ||
    isNonEmpty(filterOptions.publishers) ||
    filterOptions.bestsellers ||
    filterOptions.newcomings;

  return (
    <Drawer
      isOpen={showFilterPanel}
      onClose={() => setShowFilterPanel(false)}
      placement="right"
      size="xs"
      blockScrollOnMount={true}
    >
      <DrawerOverlay />
      <DrawerBody>
        <DrawerContent
          overflowY="auto"
          paddingBottom=""
          flexGrow={1}
          position="relative"
          backgroundColor="grey"
          minWidth="100vw"
        >
          <Flex
            borderBottom="0.0625rem solid black"
            px="1.5rem"
            paddingBottom="1rem"
            paddingTop="2rem"
            justifyContent="space-between"
          >
            <Flex gap="1rem" alignItems="center">
              <IconButton
                onClick={() => setShowFilterPanel(false)}
                _active={{}}
                _hover={{}}
                background="transparent"
                height="auto"
                size="auto"
                aria-label="close"
                icon={<IoMdClose color="black" size="1.75rem" />}
              />{" "}
              <Text as="h2" fontWeight={600} fontSize="1.25rem">
                Фільтр
              </Text>
            </Flex>
            {isAnyFilterSelected && (
              <Box
                onClick={clearFilters}
                borderBottom="0.0625rem solid black"
                py="0.25rem"
              >
                <Text fontWeight="400" size="caption">
                  Скинути
                </Text>
              </Box>
            )}
          </Flex>
          <Stack
            flexGrow={1}
            height="fit-content"
            minWidth="fit-content"
            paddingTop="1.5rem"
            paddingBottom="2.75rem"
            px="1.25rem"
            backgroundColor="grey"
            spacing="1.5rem"
            divider={
              <StackDivider
                height="0.0675rem"
                borderColor="rgba(0, 0, 0, 0.1)"
              />
            }
          >
            <Stack spacing="1.25rem">
              <Checkbox
                name="bestsellers"
                isChecked={filterOptions?.bestsellers}
                onChange={handleBookTypeCheck}
                size="mobile"
                colorScheme="none"
                width="100%"
                as="h2"
              >
                Бестселери
              </Checkbox>
              <Checkbox
                isChecked={filterOptions?.newcomings}
                onChange={handleBookTypeCheck}
                size="mobile"
                name="newcomings"
                colorScheme="none"
                as="h2"
              >
                Новинки
              </Checkbox>
            </Stack>
            <Accordion width="100%" allowMultiple>
              <Stack
                spacing="1.5rem"
                divider={
                  <StackDivider
                    height="0.0675rem"
                    borderColor="rgba(0, 0, 0, 0.1)"
                  />
                }
              >
                <AccordionItem borderWidth="0 !important">
                  <AccordionButton
                    padding="0"
                    paddingRight="0.25rem"
                    _hover={{}}
                  >
                    <Box flex="1" textAlign="left">
                      <Text size="md" fontWeight={600} as="h2">
                        Жанри
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel padding="0" borderWidth={0}>
                    <Stack
                      css={{
                        "&::-webkit-scrollbar-track": {
                          webkitBoxShadow: "inset 0 0 0.25rem rgba(0,0,0,0.3)",
                          backgroundColor: "#F5F5F5",
                        },
                        "&::-webkit-scrollbar": {
                          width: "0.5rem",
                          backgroundColor: "#F5F5F5",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#FF5500",
                          borderRadius: "1.25rem",
                        },
                      }}
                      paddingTop="1.5rem"
                      spacing="1.25rem"
                      overflowY="auto"
                      maxHeight="11.5em"
                    >
                      {genreList &&
                        genreList.genres.map((genre) => {
                          return (
                            <Checkbox
                              key={genre.id}
                              size="mobile"
                              name={genre.name}
                              onChange={handleGenreCheck}
                              isChecked={filterOptions?.genres.includes(
                                genre.name
                              )}
                              colorScheme="none"
                            >
                              {genre.name}
                            </Checkbox>
                          );
                        })}
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderWidth="0 !important">
                  <AccordionButton
                    padding="0"
                    paddingRight="0.25rem"
                    _hover={{}}
                  >
                    <Box flex="1" textAlign="left">
                      <Text size="md" fontWeight={600} as="h2">
                        Автори
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel padding="0" borderWidth={0}>
                    <Box paddingTop="1.25rem">
                      <SearchBar
                        placeholder="Пошук авторів"
                        setState={setAuthorSearchParameter}
                      />
                      <Stack
                        css={{
                          "&::-webkit-scrollbar-track": {
                            webkitBoxShadow:
                              "inset 0 0 0.25rem rgba(0,0,0,0.3)",
                            backgroundColor: "#F5F5F5",
                          },
                          "&::-webkit-scrollbar": {
                            width: "0.5rem",
                            backgroundColor: "#F5F5F5",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#FF5500",
                            borderRadius: "1.25rem",
                          },
                        }}
                        marginTop="1.5rem"
                        overflowY="auto"
                        maxHeight="10em"
                        spacing="1.25rem"
                      >
                        {authorsList && filteredAuthors?.length ? (
                          filteredAuthors?.map((author) => {
                            return (
                              <Checkbox
                                key={author.id}
                                onChange={handleAuthorCheck}
                                name={author.name}
                                isChecked={filterOptions?.authors.includes(
                                  author.name
                                )}
                                colorScheme="none"
                                size="mobile"
                              >
                                {author.name}
                              </Checkbox>
                            );
                          })
                        ) : (
                          <Text
                            opacity={0.5}
                            textAlign="center"
                            size="md"
                            fontWeight={500}
                          >
                            За вашим запитом нічого не знайдено
                          </Text>
                        )}
                      </Stack>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderWidth="0 !important">
                  <AccordionButton
                    padding="0"
                    paddingRight="0.25rem"
                    _hover={{}}
                  >
                    <Box flex="1" textAlign="left">
                      <Text size="md" fontWeight={600} as="h2">
                        Видавництва
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel padding="0" borderWidth={0}>
                    <Box paddingTop="1.25rem">
                      <SearchBar
                        placeholder="Пошук видавництв"
                        setState={setPublisherSearchParameter}
                      />
                      <Stack
                        css={{
                          "&::-webkit-scrollbar-track": {
                            webkitBoxShadow:
                              "inset 0 0 0.25rem rgba(0,0,0,0.3)",
                            backgroundColor: "#F5F5F5",
                          },
                          "&::-webkit-scrollbar": {
                            width: "0.5rem",
                            backgroundColor: "#F5F5F5",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#FF5500",
                            borderRadius: "1.25rem",
                          },
                        }}
                        marginTop="1.5rem"
                        overflowY="auto"
                        maxHeight="10em"
                        spacing="1.25rem"
                      >
                        {publisherList && filteredPublishers?.length ? (
                          filteredPublishers?.map((publisher) => {
                            return (
                              <Checkbox
                                key={publisher.id}
                                name={publisher.name}
                                isChecked={filterOptions?.publishers.includes(
                                  publisher.name
                                )}
                                onChange={handlePublisherCheck}
                                colorScheme="none"
                                size="mobile"
                              >
                                {publisher.name}
                              </Checkbox>
                            );
                          })
                        ) : (
                          <Text
                            opacity={0.5}
                            textAlign="center"
                            size="md"
                            fontWeight={500}
                          >
                            За вашим запитом нічого не знайдено
                          </Text>
                        )}
                      </Stack>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Stack>
            </Accordion>
          </Stack>
          {isAnyFilterSelected && (
            <Box
              position="sticky"
              bottom="0rem"
              width="100vw"
              backgroundColor="white"
              padding="1.25rem"
            >
              <Button
                isLoading={booksLoading}
                onClick={() => setShowFilterPanel(false)}
                height="fit-content"
                backgroundColor="orange"
                width="100%"
                py="1.25rem"
              >
                <Text
                  lineHeight="1rem"
                  color={"white"}
                  fontWeight={500}
                  size="md"
                >
                  Показати результати ({bookList?.paging.totalElements})
                </Text>
              </Button>
            </Box>
          )}
        </DrawerContent>
      </DrawerBody>
    </Drawer>
  );
};

export default MobileFilterPanel;
