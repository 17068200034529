import {
  Button,
  Stack,
  Text,
  Box,
  StackDivider,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import { useFetchPosts } from "../../graphcms/hooks/Post";

import PostPreviewItem from "../../features/PostsPage/components/PostPreviewItem/PostPreviewItem";
import Seo from "../../ui/components/Seo/Seo";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import Loader from "../../ui/components/Loader/Loader";

const PostsPage = () => {
  const {
    posts,
    loading: isLoading,
    isFetching,
    error,
    loadMore,
    hasMore,
    totalElements,
  } = useFetchPosts({
    initialPageSize: 4,
  });

  return (
    <>
      <Seo description={`Блог Читанки`} title={"Блог Читанки"} />
      <Box
        paddingTop={{
          base: "0rem",
          sm: "1.25rem",
          md: "1.75rem",
          xl: "2.5rem",
        }}
        px={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
      >
        <Breadcrumb
          breadcrumbItems={[
            { title: "Головна", path: "/" },
            { title: "Блог", path: "/posts" },
          ]}
        />
        <Box
          marginBottom={{
            base: "2rem",
            sm: "1.25rem",
            md: "2rem",
          }}
          marginTop={{
            base: "1rem",
            sm: "0.75rem",
            md: "1.75rem",
            xl: "2.5rem",
          }}
        >
          <Stack
            flexDirection={{ base: "column", sm: "row" }}
            alignItems={{ base: "flex-start", sm: "flex-end" }}
            gap={{ base: "0.5rem", sm: "1rem", xl: "2rem" }}
            justifyContent="space-between"
            marginBottom={{ base: "1rem", md: "1.5rem", xl: "2rem" }}
          >
            <Text
              fontWeight={600}
              as="h1"
              lineHeight="1"
              fontSize={{
                base: "1.75rem",
                sm: "1.5rem",
                md: "1,75rem",
                xl: "2.25rem",
              }}
              variant="primary"
            >
              Блог
            </Text>
            <Text
              fontWeight={400}
              lineHeight="1rem"
              variant="secondary"
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
            >
              {totalElements !== undefined &&
                `${totalElements} ${
                  totalElements > 0 && totalElements < 5
                    ? totalElements == 1
                      ? "пост"
                      : "пости"
                    : "постів"
                }`}
            </Text>
          </Stack>
        </Box>
        {posts.length > 0 ? (
          <Stack gap={{ base: "1rem", sm: "2rem", xl: "3rem" }}>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              gap={{ base: "1rem", md:"1.5rem", xl: "2rem" }}
              rowGap={{ base: "1.5rem", xl: "3rem" }}

            >
              {posts.map((post) => (
                <PostPreviewItem key={post.node.slug} {...post.node} />
              ))}
            </SimpleGrid>

            {hasMore && (
              <Button
                _hover={{ backgroundColor: "black" }}
                isLoading={isFetching}
                disabled={isFetching}
                onClick={loadMore}
                transition="all 0.3s"
                height="auto"
                backgroundColor="orange"
                width={{ base: "100%", sm: "auto" }}
                py={{ base: "0.75rem", md: "1rem", xl: "1.125rem" }}
                px={{ base: "1rem", md: "1.25rem", xl: "1.375rem" }}
                alignSelf="center"
              >
                <Text
                  lineHeight="1.25rem"
                  size={{ base: "xs", md: "sm", xl: "md" }}
                  color="white"
                >
                  Більше постів
                </Text>
              </Button>
            )}
          </Stack>
        ) : isLoading ? (
          <Loader width="100%" />
        ) : (
          <Center height={{ base: "50vh" }}>
            <Text
              opacity={0.5}
              size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
              fontWeight={500}
            >
              На даний момент постів немає
            </Text>
          </Center>
        )}
      </Box>
    </>
  );
};

export default PostsPage;
