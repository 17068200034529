import { Link, Stack } from "@chakra-ui/react";
import { FunctionComponent } from "react";

type YouTubePlayerProps = {
  videoUrl: string;
  width?: string;
};

const YouTubePlayer: FunctionComponent<YouTubePlayerProps> = ({
  videoUrl,
  width = "100%",
}) => {
  const getVideoIdFromUrl = (url: string) => {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v") || "";
  };

  const videoId = getVideoIdFromUrl(videoUrl);

  if (!videoId) {
    return <p>Invalid YouTube URL</p>;
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <Stack
      gap="0.25rem"
      textDecor="underline"
      letterSpacing="-0.7px"
      fontWeight="500"
      alignItems="flex-end"
      width="100%"
      justifyItems="stretch"
      position="relative"
      css={{
        ".player-wrapper": {
          aspectRatio: "16/9",
          height: "auto !important",
        },
      }}
    >
      <iframe
        src={embedUrl}
        width={width}
        height={"fit-content"}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          width: "100%",
          aspectRatio: 16 / 9,
          borderRadius: "1.25rem",
        }}
        title="YouTube Video Player"
      ></iframe>
      <Link href={videoUrl} mt="0 !important" opacity="0.5" noOfLines={1} fontSize="sm">
        {videoUrl}
      </Link>
    </Stack>
  );
};

export default YouTubePlayer;
