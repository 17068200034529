import { Navigate, useLocation, useParams } from "react-router-dom";
import { Box, Show, VStack, useBreakpoint } from "@chakra-ui/react";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import BookDetailsSection from "../../features/BookDetailsPage/components/BookDetailsSection/BookDetailsSection";
import BookList from "../../features/BookDetailsPage/components/BookList/BookList";
import MobileBookDetailsSection from "../../features/BookDetailsPage/components/BookDetailsSection/MobileBooksDetailSection";
import { useBookDetailsByTransliteration } from "../../features/BookDetailsPage/api/client";
import ErrorMessage from "../../ui/components/ErrorMessage/ErrorMessage";
import MyContentLoader from "../../features/BookDetailsPage/components/ContentLoader/ContentLoader";
import MobileContentLoader from "../../features/BookDetailsPage/components/ContentLoader/MobileContentLoader";
import { useEffect } from "react";
import Seo from "../../ui/components/Seo/Seo";
import { AxiosError } from "axios";

const BookDetailsPage: React.FC = () => {
  const { id } = useParams();
  const {
    data: book,
    isLoading,
    error,
  } = useBookDetailsByTransliteration(id ?? "");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [location]);

  if (error) {
    if ((error as AxiosError)?.response?.status == 404) {
      return <Navigate to="/" replace />;
    }
    return <ErrorMessage />;
  }

  return (
    <>
      <Seo
        description={book?.description ?? "Деталі книжки"}
        title={book?.name ?? "Деталі книжки"}
      />
      <Box
        paddingLeft={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
        paddingRight={{
          base: "0.5rem",
          md: "0.875rem",
          xl: "1.875rem",
          "2xl": "4.25rem",
        }}
      >
        {isLoading ? (
          <Box width="100%">
            <Show ssr={false} above="sm">
              <MyContentLoader />
            </Show>
            <Show ssr={false} below="sm">
              <MobileContentLoader />
            </Show>
          </Box>
        ) : (
          <Box maxWidth="100%">
            <Breadcrumb
              breadcrumbItems={[
                { path: "/", title: "Головна" },
                { path: "/genres", title: "Жанри" },
                {
                  path: `/genres/${book?.genres?.at(0) ?? "/"}`,
                  title: book?.genres?.at(0) ?? "",
                  payload: {
                    categoryName: book?.genres?.at(0),
                  },
                },
                { title: book?.name ?? "" },
              ]}
            />
            <VStack
              spacing={{ base: "3rem", sm: "2rem", md: "3.5rem", xl: "5rem" }}
              marginTop={{ base: "1.25rem", md: "1.5rem", xl: "2rem" }}
            >
              {book && (
                <Box width="100%">
                  <Show ssr={false} below="sm">
                    <MobileBookDetailsSection {...book} />
                  </Show>
                  <Show ssr={false} above="sm">
                    <BookDetailsSection {...book} />
                  </Show>
                </Box>
              )}
              <BookList type="authors" name={book?.authors[0] ?? ""} />
              <BookList type="publishers" name={book?.publishingHouse ?? ""} />
            </VStack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BookDetailsPage;
