import { gql } from "@apollo/client";

export const fetchPostsPreviewsQuery = gql`
  query fetchPosts($currentDate: Date, $first: Int) {
    postsConnection(
      orderBy: publishingDate_DESC
      where: { publishingDate_lte: $currentDate }
      first: $first
    ) {
      edges {
        node {
          title
          slug
          heroImage {
            url
          }
          publishingDate
        }
      }
      pageInfo {
        hasNextPage
      }
      aggregate {
        count
      }
    }
  }
`;

export const fetchPostQuery = gql`
  query fetchPost($slug: String) {
    post(where: { slug: $slug }) {
      title
      slug
      heroImage {
        url
      }
      publishingDate
      content {
        raw
      }
      excerpt
    }
  }
`;
