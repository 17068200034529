import { Box, Text, Image, forwardRef } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GenreType } from "../../../SelectedCategoryPage/api/types";
import defaultCover from "../../../../assets/Genre-default.svg";

export const Category = forwardRef<GenreType, "div">(
  (
    {
      id,
      name,
      backgroundImageUrl,
      colorCode,
    },
    ref
  ) => {
    const navigate = useNavigate();

    return (
      <Box
        scrollSnapAlign="start"
        ref={ref}
        display="flex"
        alignItems="flex-end"
        transition={"all 0.3s"}
        _hover={{
          base: "",
          sm: {
            transform: "translate(-0.3rem, -0.3rem)",
            boxShadow: "0.3rem 0.3rem  rgba(0,0,0,1)",
          },
        }}
        cursor="pointer"
        onClick={() => navigate(`/genres/${name}`)}
        overflow="hidden"
        position="relative"
        borderRadius="0.75rem"
        px={{ base: "1rem", sm: "0.65rem", md: "0.8rem", xl: "1rem" }}
        paddingBottom={{
          base: "1rem",
          sm: "0.65rem",
          md: "0.8rem",
          xl: "1rem",
        }}
        paddingTop={{
          base: "2.75rem",
          sm: "2.75rem",
          md: "3rem",
          xl: "3.75rem",
        }}
        minWidth={{
          base: "10rem",
          sm: "9rem",
          xl: "11.25rem",
        }}
        width={{
          base: "47.7%",
          sm: "40%",
          md: "15.25%",
          xl: "13%",
          "2xl": "11.5%",
        }}
        backgroundColor={colorCode??"#BFA5A7"}
        maxHeight={{ base: "5rem", sm: "4rem", md: "5rem", xl: "6.25rem" }}
      >
        <Text
          sx={{ wordWrap: "normal !important" }}
          fontWeight={500}
          maxWidth={{ base: "65%", sm: "60%" }}
          lineHeight={{ base: "1rem", xl: "1.25rem" }}
          color="white"
          size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
          variant="primary"
          zIndex={100}
        >
          {name}
        </Text>
        <Image
          height="87%"
          objectFit="cover"
          bottom="0rem"
          right="-10%"
          position="absolute"
          src={backgroundImageUrl}
          fallbackSrc={defaultCover}
          alt={name}
        />
      </Box>
    );
  }
);
